import request from '../utils/request'
import baseUrl from './baseUrl'
export const activeList = (data) => request({
  url: baseUrl + '/activityInfo/selectForBack',
  method: 'POST',
  data
})
export const addOrEditActive = (data) => request({
  url: baseUrl + '/activityInfo/add',
  method: 'POST',
  data
})
export const activeDetail = (data) => request({
  url: baseUrl + '/activityInfo/selectForId',
  method: 'POST',
  data
})
export const changeStatus = (data) => request({
  url: baseUrl + '/activityInfo/modify',
  method: 'POST',
  data
})
export const delActive = (data) => request({
  url: baseUrl + '/activityInfo/deleteById',
  method: 'POST',
  data
})
export const regListCode = (data) => request({
  url: baseUrl + '/activityInfo/selectErCode',
  method: 'POST',
  data
})
// 区域管理
export const selectActivityArea = (data) => request({
  url: baseUrl + '/activityArea/selectForBack',
  method: 'POST',
  data
})
export const addActivityArea = (data) => request({
  url: baseUrl + '/activityArea/add',
  method: 'POST',
  data
})
export const deleteActivityArea = (data) => request({
  url: baseUrl + '/activityArea/deleteById',
  method: 'POST',
  data
})
export const selectErCode = (data) => request({
  url: baseUrl + '/activityArea/selectErCode',
  method: 'POST',
  data
})
export const selectForBack = (data) => request({
  url: baseUrl + '/activityClick/selectForBack',
  method: 'POST',
  data
})
export const exportExcel = (data) => request({
  url: baseUrl + '/activityClick/exportExcel',
  method: 'POST',
  data,
  responseType: "blob"
})